import React from "react";
import { Col, Row, Spin } from "antd";
import "./styles/adminStatistics.css";
import { db } from "../firebase/index";
import { VictoryPie } from "victory";

class adminStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dealers: [],
      customers: [],
      purchases: [],
      dealCust: [],
      loading: true
    };
  }

  componentWillMount() {
    this.loadData();
  }

  async loadData() {
    const dealers = async data => {
      if (data.val()) {
        let deals = Object.values(data.val());
        await deals.forEach((data, index) => {
          deals[index].customers = [];
          deals[index].y = 0;
          deals[index].x = data.name || "dealer " + index;
        });
        this.setState({ dealers: deals });
      }
    };

    const customers = data => {
      if (data.val()) {
        this.setState({ customers: Object.values(data.val()) });
      }
    };

    const purchases = data => {
      if (data.val()) {
        this.setState({ purchases: Object.values(data.val()) });
      }
    };
    await db
      .ref("/users")
      .orderByChild("role")
      .equalTo("dealer")
      .once("value", dealers);
    await db.ref("/customers").once("value", customers);
    await db.ref("/purchases").once("value", purchases);
    let dealer = this.state.dealers;
    let customer = this.state.customers;
    customer.forEach((cust, index) => {
      dealer.forEach(async (deal, indo) => {
        if (cust.dealer === deal.uid) {
          await dealer[indo].customers.push(cust);
          dealer[indo].y += 1;
        }
      });
    });
    console.log(dealer);
    this.setState({ dealCust: dealer, loading: false });
  }

  render() {
    return (
      <Row>
        {this.state.loading ? (
          <Col className="spinner">
            <Spin />
            <span>Loading Data...</span>
          </Col>
        ) : (
          <Col>
            <VictoryPie data={this.state.dealCust} />
          </Col>
        )}
      </Row>
    );
  }
}

export default adminStatistics;
