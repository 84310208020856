import React from "react";
import {
  Col,
  Row,
  Table
} from "antd";
import {
  db
} from "../firebase/index";
import "./styles/createSupplier.css";

class adminAllCustomers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      allDealers: []
    };

    this.columns = [{
      title: "Customer / Company Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address"
    },
    {
      title: "Contact Person",
      dataIndex: "contactPerson",
      key: "contactPerson"
    },
    {
      title: "Product type",
      dataIndex: "type",
      key: "type"
    },
    {
      title: "Production Quantity",
      dataIndex: "productionQuantity",
      key: "productionQuantity"
    },
    {
      title: "Products Sold",
      dataIndex: "prods",
      key: "prods"
    },
    {
      title: "dealer",
      dataIndex: "",
      key: "A",
      render: record => {
        return (<p> {this.getDealerName(record.dealer)} </p>)
      }
    }
    ];
  }

  getDealerName = id => {
    for (var i of this.state.allDealers) {
      if (i.id === id) {
        return i.name
      }
    }
    return "Invalid Dealer"
  }


  componentWillMount() {
    const UpdateCustomer = data => {
      let keys = Object.keys(data.val());
      let values = Object.values(data.val());
      let products = [];
      keys.forEach((data, value) => {
        products.push({
          key: data,
          ...values[value]
        });
      });
      if (data.val()) {
        this.setState({
          customers: products
        });
      }
    };
    db.ref("customers").once("value", UpdateCustomer);

    const allsupps = datum => {
      if (datum) {
        let suppliers = []
        datum.forEach((data, index) => {
          suppliers.push({ id: data.key, ...data.val() })
        })
        this.setState({
          allDealers: suppliers
        });
      }

    };
    db.ref("users")
      .orderByChild("role")
      .equalTo("dealer")
      .once("value", allsupps);
  }

  render() {
    return (<div>
      <Row>
        <Col>
          <Table dataSource={
            this.state.customers
          }
            columns={
              this.columns
            }
            rowKey={
              record => record.key
            }
          /> </Col> </Row>
    </div>
    );
  }
}

export default adminAllCustomers;