import { SET_AUTH } from "../actions/types";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH: {
      const auth = action.payload.auth;
      return {
        ...state,
        user: auth
      };
    }

    default:
      return state;
  }
}
