import React from "react";
import "./styles/dashboard.css";
import { Layout, Menu, Icon, Modal, Dropdown, Avatar } from "antd";
import logo from "../assets/logo.png";
import { setAuth } from "../actions/index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Route, NavLink } from "react-router-dom";
import createSuppliers from "../components/createSuppliers";
import supplierCustomers from "../components/supplierCustomers";
import adminStatistics from "../components/adminStatistics";
import supplierPurchase from "../components/supplierPurchase";
import adminAllCustomers from "../components/adminAllCustomers";
import adminAllPurchases from "../components/adminAllPurchases";
import AdminProducts from "../components/adminAllProducts";
import supplierHistory from "../components/supplierHistory";
import AdminHistory from "../components/adminHistory";

const { Header, Content, Footer, Sider } = Layout;
const confirm = Modal.confirm;

class dashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.menu = (
      <Menu>
        <Menu.Item>
          <Icon type="user" /> &nbsp;
          <span>Role : {this.props.auth.user.role || "Dealer"}</span>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            const self = this;
            confirm({
              title: "Logout",
              content: "Are you sure to log out of the system ?",
              onOk() {
                self.props.setAuth(null);
                self.props.history.push("/");
              },
              onCancel() { }
            });
          }}
        >
          <Icon type="logout" /> &nbsp;<span>Logout</span>
        </Menu.Item>
      </Menu>
    );
  }
  render() {
    return (
      <Layout className="rootContainer">
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={broken => { }}
          onCollapse={(collapsed, type) => { }}
        >
          <div>
            <img src={logo} alt="logo" className="logo" />
          </div>
          {this.props.auth.user.role === "superAdmin" && (
            <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
              <Menu.Item key="1">
                <NavLink to="/components/suppliers">
                  <Icon type="user" />
                  &nbsp;Manage Dealers
                </NavLink>
              </Menu.Item>
              <Menu.Item key="2">
                <NavLink to="/components/dealer-products">
                  <Icon type="user" />
                  &nbsp;Manage Products List
                </NavLink>
              </Menu.Item>
              <Menu.Item key="3">
                <NavLink to="/components/all-customers">
                  <Icon type="pic-center" />
                  &nbsp;All Customers
                </NavLink>
              </Menu.Item>
              <Menu.Item key="4">
                <NavLink to="/components/all-purchases">
                  <Icon type="snippets" />
                  &nbsp;All Purchases
                </NavLink>
              </Menu.Item>
              <Menu.Item key="5">
                <NavLink to="/components/history">
                  <Icon type="clock-circle" />
                  &nbsp;History
                </NavLink>
              </Menu.Item>
              {/* <Menu.Item key="2">
                <NavLink to="/components/statistics">
                  <Icon type="line-chart" />
                  &nbsp;Statistics
                </NavLink>
              </Menu.Item> */}
            </Menu>
          )}
          {this.props.auth.user.role === "dealer" && (
            <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
              <Menu.Item key="1">
                <NavLink to="/components/customers">
                  <Icon type="user" />
                  &nbsp;Customers
                </NavLink>
              </Menu.Item>
              <Menu.Item key="2">
                <NavLink to="/components/purchase">
                  <Icon type="file-add" />
                  &nbsp;Purchases
                </NavLink>
              </Menu.Item>
              <Menu.Item key="3">
                <NavLink to="/components/history">
                  <Icon type="clock-circle" />
                  &nbsp;History
                </NavLink>
              </Menu.Item>
            </Menu>
          )}
        </Sider>
        <Layout>
          <Header className="header">
            <Dropdown overlay={this.menu}>
              <div className="logoutbtn">
                <Avatar>
                  {this.props.auth.user.name[0].toUpperCase() || "USER"}
                </Avatar>{" "}
                &nbsp; <span>{this.props.auth.user.name}</span>
              </div>
            </Dropdown>
          </Header>
          <Content style={{ margin: "24px 16px 0 16px" }}>
            <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>
              {this.props.auth.user.role === "superAdmin" && (
                <div>
                  <Route
                    path={`${this.props.match.path}/suppliers`}
                    component={createSuppliers}
                  />
                  <Route
                    path={`${this.props.match.path}/all-customers`}
                    component={adminAllCustomers}
                  />
                  <Route
                    path={`${this.props.match.path}/statistics`}
                    component={adminStatistics}
                  />
                  <Route
                    path={`${this.props.match.path}/all-purchases`}
                    component={adminAllPurchases}
                  />
                  <Route
                    path={`${this.props.match.path}/dealer-products`}
                    component={AdminProducts}
                  />
                  <Route
                    path={`${this.props.match.path}/history`}
                    component={AdminHistory}
                  />
                </div>
              )}
              {this.props.auth.user.role === "dealer" && (
                <div>
                  <Route
                    path={`${this.props.match.path}/customers`}
                    component={supplierCustomers}
                  />
                  <Route
                    path={`${this.props.match.path}/purchase`}
                    component={supplierPurchase}
                  />
                  <Route
                    path={`${this.props.match.path}/history`}
                    component={supplierHistory}
                  />
                </div>
              )}
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Dealer Management System © 2019
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(
  mapStateToProps,
  { setAuth }
)(withRouter(dashboardContainer));
