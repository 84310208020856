import React from "react";
import {
  Col,
  Row,
  Table,
  Button,
  Modal,
  Input,
  Icon,
  message,
  InputNumber
} from "antd";
import { db } from "../firebase/index";
import { connect } from "react-redux";
import "./styles/createSupplier.css";
const ButtonGroup = Button.Group;
const { TextArea } = Input;
const confirm = Modal.confirm;

class supplierCustomers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      addModal: false,
      editModal: false,
      name: "",
      address: "",
      contactPerson: "",
      contactPosition: "",
      contactNumber: "",
      productionQuantity: null,
      prods: "",
      type: "",
      currentCustomer: {}
    };

    this.columns = [
      {
        title: "Customer / Company Name",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address"
      },
      {
        title: "Contact Person",
        dataIndex: "contactPerson",
        key: "contactPerson"
      },
      {
        title: "Product type",
        dataIndex: "type",
        key: "type"
      },
      {
        title: "Production Quantity",
        dataIndex: "productionQuantity",
        key: "productionQuantity"
      },
      {
        title: "Products Sold",
        dataIndex: "prods",
        key: "prods"
      },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        render: record => (
          <ButtonGroup>
            <Button
              onClick={() => {
                this.setState({ currentCustomer: record, editModal: true });
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                this.handleDelete(record.id);
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        )
      }
    ];
    this.updateDB();
  }

  updateDB = () => {
    const allcusts = datum => {
      let custs = [];
      if (datum.val() != null) {
        let data = Object.keys(datum.val());
        data.forEach((dato, index) => {
          custs.push({ id: dato, ...datum.val()[dato] });
        });
        this.setState({ customers: custs });
      } else {
        this.setState({
          customers: []
        });
      }
    };
    db.ref("customers")
      .orderByChild("dealer")
      .equalTo(this.props.auth.user.uid)
      .once("value", allcusts);
  };

  handleSubmit = () => {
    if (
      this.state.name === "" ||
      this.state.address === "" ||
      this.state.contactPerson === "" ||
      this.state.productionQuantity === "" ||
      this.state.productionQuantity === null ||
      this.state.prods === "" ||
      this.state.type === "" ||
      this.state.contactNumber === "" ||
      this.state.contactPosition === ""
    ) {
      message.warning("Kindly fill in all the fields");
      return;
    }
    const customer = {
      name: this.state.name,
      address: this.state.address,
      contactPerson: this.state.contactPerson,
      contactPosition: this.state.contactPosition,
      contactNumber: this.state.contactNumber,
      productionQuantity: this.state.productionQuantity,
      prods: this.state.prods,
      dealer: this.props.auth.user.uid,
      type: this.state.type
    };
    db.ref("customers")
      .push(customer)
      .then(data => {
        message.success("Customer added");
        this.updateDB();
        this.setState({ addModal: false });
      })
      .catch(err => {
        console.log(err.code);
      });
  };

  handleUpdate = () => {
    db.ref("customers/" + this.state.currentCustomer.id)
      .update({
        name: this.state.currentCustomer.name,
        address: this.state.currentCustomer.address,
        contactPerson: this.state.currentCustomer.contactPerson,
        productionQuantity: this.state.currentCustomer.productionQuantity,
        prods: this.state.currentCustomer.prods,
        type: this.state.currentCustomer.type,
        contactPosition: this.state.currentCustomer.contactPosition,
        contactNumber: this.state.currentCustomer.contactNumber
      })
      .then(data => {
        message.success("Customer Details Updated");
        this.updateDB();
        this.setState({ editModal: false, currentCustomer: {} });
      })
      .catch(err => {
        message.error(err.code);
      });
  };

  handleDelete = id => {
    const self = this;
    confirm({
      title: "Delete",
      content: "Are you sure to delete this customer ?",
      onOk() {
        db.ref("customers/")
          .child(id)
          .remove()
          .then(data => {
            message.success("Customer Deleted");
            self.updateDB();
          })
          .catch(err => {
            console.log(err.code);
          });
      },
      onCancel() {}
    });
  };

  render() {
    return (
      <div>
        <Row style={{ margin: 10 }}>
          <Col>
            <Button
              type="primary"
              onClick={() => this.setState({ addModal: true })}
            >
              Add Customer
            </Button>
          </Col>
        </Row>
        <Modal
          title="Add Customer"
          visible={this.state.addModal}
          onOk={() => this.handleSubmit()}
          onCancel={() => this.setState({ addModal: false })}
          okText={"Save Customer"}
        >
          <div>
            <Input
              className="all-inputs"
              size="large"
              placeholder="Enter Customer Name / Company Name"
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              onChange={name => {
                this.setState({ name: name.target.value });
              }}
              value={this.state.name}
            />
            <TextArea
              className="all-inputs"
              placeholder="Enter Customer Address"
              size="large"
              autosize={{ minRows: 2, maxRows: 6 }}
              prefix={
                <Icon type="environment" style={{ color: "rgba(0,0,0,.25)" }} />
              }
              onChange={address => {
                this.setState({ address: address.target.value });
              }}
              value={this.state.address}
            />
            <div className="isolate">
              <label>Contact Person Details</label>
              <Input
                className="all-inputs"
                size="large"
                placeholder="Person Name"
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                onChange={contactPerson => {
                  this.setState({ contactPerson: contactPerson.target.value });
                }}
                value={this.state.contactPerson}
              />
              <Input
                className="all-inputs"
                size="large"
                placeholder="Position"
                prefix={
                  <Icon type="contacts" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                onChange={contactPosition => {
                  this.setState({
                    contactPosition: contactPosition.target.value
                  });
                }}
                value={this.state.contactPosition}
              />
              <Input
                className="all-inputs"
                size="large"
                placeholder="Contact Number"
                prefix={
                  <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                onChange={contactNumber => {
                  this.setState({ contactNumber: contactNumber.target.value });
                }}
                value={this.state.contactNumber}
              />
            </div>
            <Input
              className="all-inputs"
              size="large"
              placeholder="Customer Product type"
              prefix={
                <Icon
                  type="unordered-list"
                  style={{ color: "rgba(0,0,0,.25)" }}
                />
              }
              onChange={type => {
                this.setState({ type: type.target.value });
              }}
              value={this.state.type}
            />
            <InputNumber
              className="all-inputs"
              size="large"
              placeholder={"Customer Production Quantity"}
              onChange={productionQuantity => {
                this.setState({
                  productionQuantity: productionQuantity
                });
              }}
              value={this.state.productionQuantity}
            />
            <TextArea
              className="all-inputs"
              placeholder="Products Sold"
              size="large"
              autosize={{ minRows: 2, maxRows: 6 }}
              prefix={
                <Icon
                  type="money-collect"
                  style={{ color: "rgba(0,0,0,.25)" }}
                />
              }
              onChange={prods => {
                this.setState({ prods: prods.target.value });
              }}
              value={this.state.prods}
            />
          </div>
        </Modal>

        <Modal
          title="Edit Customer"
          visible={this.state.editModal}
          onOk={() => this.handleUpdate()}
          onCancel={() => this.setState({ editModal: false })}
          okText={"Update Customer"}
        >
          <div>
            <Input
              className="all-inputs"
              size="large"
              placeholder="Enter Customer Name / Company Name"
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              onChange={name => {
                let temp = this.state.currentCustomer;
                temp.name = name.target.value;
                this.setState({ currentCustomer: temp });
              }}
              value={this.state.currentCustomer.name}
            />
            <TextArea
              className="all-inputs"
              placeholder="Enter Customer Address"
              size="large"
              autosize={{ minRows: 2, maxRows: 6 }}
              prefix={
                <Icon type="environment" style={{ color: "rgba(0,0,0,.25)" }} />
              }
              onChange={address => {
                let temp = this.state.currentCustomer;
                temp.address = address.target.value;
                this.setState({ currentCustomer: temp });
              }}
              value={this.state.currentCustomer.address}
            />
            <div className="isolate">
              <label>Contact Person Details</label>
              <Input
                className="all-inputs"
                size="large"
                placeholder="Person Name"
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                onChange={contactPerson => {
                  let temp = this.state.currentCustomer;
                  temp.contactPerson = contactPerson.target.value;
                  this.setState({ currentCustomer: temp });
                }}
                value={this.state.currentCustomer.contactPerson}
              />
              <Input
                className="all-inputs"
                size="large"
                placeholder="Position"
                prefix={
                  <Icon type="contacts" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                onChange={contactPosition => {
                  let temp = this.state.currentCustomer;
                  temp.contactPosition = contactPosition.target.value;
                  this.setState({ currentCustomer: temp });
                }}
                value={this.state.currentCustomer.contactPosition}
              />
              <Input
                className="all-inputs"
                size="large"
                placeholder="Contact Number"
                prefix={
                  <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                onChange={contactNumber => {
                  let temp = this.state.currentCustomer;
                  temp.contactNumber = contactNumber.target.value;
                  this.setState({ currentCustomer: temp });
                }}
                value={this.state.currentCustomer.contactNumber}
              />
            </div>
            <Input
              className="all-inputs"
              size="large"
              placeholder="Customer Product type"
              prefix={
                <Icon
                  type="unordered-list"
                  style={{ color: "rgba(0,0,0,.25)" }}
                />
              }
              onChange={type => {
                let temp = this.state.currentCustomer;
                temp.type = type.target.value;
                this.setState({ currentCustomer: temp });
              }}
              value={this.state.currentCustomer.type}
            />
            <InputNumber
              className="all-inputs"
              size="large"
              min={1}
              max={100000}
              defaultValue={1}
              prefix={
                <Icon
                  type="vertical-align-bottom"
                  style={{ color: "rgba(0,0,0,.25)" }}
                />
              }
              placeholder={"Customer Production Quantity"}
              onChange={productionQuantity => {
                let temp = this.state.currentCustomer;
                temp.productionQuantity = productionQuantity;
                this.setState({ currentCustomer: temp });
              }}
              value={this.state.currentCustomer.productionQuantity}
            />
            <TextArea
              className="all-inputs"
              placeholder="Products Sold"
              size="large"
              autosize={{ minRows: 2, maxRows: 6 }}
              prefix={
                <Icon
                  type="money-collect"
                  style={{ color: "rgba(0,0,0,.25)" }}
                />
              }
              onChange={prods => {
                let temp = this.state.currentCustomer;
                temp.prods = prods.target.value;
                this.setState({ currentCustomer: temp });
              }}
              value={this.state.currentCustomer.prods}
            />
          </div>
        </Modal>
        <Row>
          <Col>
            <Table
              dataSource={this.state.customers}
              columns={this.columns}
              rowKey={record => record.id}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps)(supplierCustomers);
