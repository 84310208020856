import React from "react";
import {
  Col,
  Row,
  Table,
  Button,
  Modal,
  Input,
  message
} from "antd";
import { db } from "../firebase/index";
import { connect } from "react-redux";
import "./styles/createSupplier.css";
const ButtonGroup = Button.Group;
const confirm = Modal.confirm;

class AdminProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allProds: [],
      addModal: false,
      editModal: false,
      current: {},
      name:"",
      code:""
    };

    this.columns = [
      {
        title: "Product name",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "Product code",
        dataIndex: "code",
        key: "code"
      },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        render: record => (
          <ButtonGroup>
            <Button
              onClick={() => {
                this.setState({ current: record, editModal: true });
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                this.handleDelete(record.id);
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        )
      }
    ];
    this.updateDB();
  }

  updateDB = () => {
    const all = datum => {
        if(datum){
            let products = [];
            datum.forEach((data,index)=>{
                products.push({id:data.key,...data.val()})
            })
            this.setState({allProds:products})
        }
    };
    db.ref("dealer_prods")
      .once("value", all);
  };

  handleSubmit = () => {
    if (
      this.state.name === "" || 
      this.state.code === ""
    ) {
      message.warning("Kindly fill in all the fields");
      return;
    }
    const prod = {
      name: this.state.name,
      code: this.state.code
    };
    db.ref("dealer_prods")
      .push(prod)
      .then(data => {
        message.success("Product Added");
        this.updateDB();
        this.setState({ addModal: false });
      })
      .catch(err => {
        console.log(err.code);
      });
  };

  handleUpdate = () => {
    db.ref("dealer_prods/" + this.state.current.id)
      .update({
        name: this.state.current.name,
        code: this.state.current.code
      })
      .then(data => {
        message.success("Product Details Updated");
        this.updateDB();
        this.setState({ editModal: false, current: {} });
      })
      .catch(err => {
        message.error(err.code);
      });
  };

  handleDelete = id => {
    const self = this;
    confirm({
      title: "Delete",
      content: "Are you sure to delete this purchase ?",
      onOk() {
        db.ref("dealer_prods/")
          .child(id)
          .remove()
          .then(data => {
            message.success("Product Deleted");
            self.updateDB();
          })
          .catch(err => {
            console.log(err.code);
          });
      },
      onCancel() {}
    });
  };

  render() {
    return (
      <div>
        <Row style={{ margin: 10 }}>
          <Col>
            <Button
              type="primary"
              onClick={() => this.setState({ addModal: true })}
            >
              Add Product
            </Button>
          </Col>
        </Row>
        <Modal
          title="Add Product"
          visible={this.state.addModal}
          onOk={() => this.handleSubmit()}
          onCancel={() => this.setState({ addModal: false })}
          okText={"Save Product"}
        >
          <div>
          <Input
              className="all-inputs"
              size="large"
              placeholder="Product Name"
              onChange={name => {
                this.setState({ name: name.target.value });
              }}
              value={this.state.name}
            />
            <Input
              className="all-inputs"
              size="large"
              placeholder="Product Code"
              onChange={code => {
                this.setState({ code: code.target.value });
              }}
              value={this.state.code}
            />
          </div>
        </Modal>

        <Modal
          title="Edit Product"
          visible={this.state.editModal}
          onOk={() => this.handleUpdate()}
          onCancel={() => this.setState({ editModal: false })}
          okText={"Update Product"}
        >
          <div>
            <Input
              className="all-inputs"
              size="large"
              placeholder="Product Name"
              onChange={name => {
                let temp = this.state.current;
                temp.name = name.target.value;
                this.setState({ current: temp });
              }}
              value={this.state.current.name}
            />
            <Input
              className="all-inputs"
              size="large"
              placeholder="Product Code"
              onChange={code => {
                let temp = this.state.current;
                temp.code = code.target.value;
                this.setState({ current: temp });
              }}
              value={this.state.current.code}
            />
          </div>
        </Modal>
        <Row>
          <Col>
            <Table
              dataSource={this.state.allProds}
              columns={this.columns}
              rowKey={record => record.id}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps)(AdminProducts);
