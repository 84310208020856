import React from "react";
import { Col, Row, Table } from "antd";
import { db } from "../firebase/index";
import "./styles/createSupplier.css";

class adminAllPurchases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      purchases: [],
      allDealers: []
    };

    this.columns = [
      {
        title: "Product Sold",
        dataIndex: "prods",
        key: "prods"
      },
      {
        title: "Date Sold",
        dataIndex: "soldDate",
        key: "soldDate"
      },
      {
        title: "P/O No",
        dataIndex: "pono",
        key: "pono"
      },
      {
        title: "Price",
        dataIndex: "",
        key: "y",
        render: record =>
          record.price === null ? (
            <span>No Price</span>
          ) : (
              <span>
                {record.pricecode}
                {record.price}
              </span>
            )
      },
      {
        title: "Quantity Sold",
        dataIndex: "quantity",
        key: "quantity"
      },
      {
        title: "Date of Visit",
        dataIndex: "date",
        key: "date"
      },
      {
        title: "dealer",
        dataIndex: "",
        key: "A",
        render: record => {
          return (<p> {this.getDealerName(record.dealer)} </p>)
        }
      }
    ];
  }

  getDealerName = id => {
    for (var i of this.state.allDealers) {
      if (i.id === id) {
        return i.name
      }
    }
    return "Invalid Dealer"
  }

  componentWillMount() {
    const UpdateCustomer = data => {
      let keys = Object.keys(data.val());
      let values = Object.values(data.val());
      let products = [];
      keys.forEach((data, value) => {
        products.push({ key: data, ...values[value] });
      });
      if (data.val()) {
        this.setState({ purchases: products });
      }
    };
    db.ref("purchases").once("value", UpdateCustomer);

    const allsupps = datum => {
      if (datum) {
        let suppliers = []
        datum.forEach((data, index) => {
          suppliers.push({ id: data.key, ...data.val() })
        })
        this.setState({
          allDealers: suppliers
        });
      }

    };
    db.ref("users")
      .orderByChild("role")
      .equalTo("dealer")
      .once("value", allsupps);
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <Table
              dataSource={this.state.purchases}
              columns={this.columns}
              rowKey={record => record.key}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default adminAllPurchases;
