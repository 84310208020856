import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
let firebaseConfig = {
  apiKey: "AIzaSyBnLuLRYEQbuGuGIhRfnyHMJs0CCoO6Gdc",
  authDomain: "dealer-management-system-f3705.firebaseapp.com",
  databaseURL: "https://dealer-management-system-f3705.firebaseio.com",
  projectId: "dealer-management-system-f3705",
  storageBucket: "dealer-management-system-f3705.appspot.com",
  messagingSenderId: "509788378754",
  appId: "1:509788378754:web:a0240a1142f67e2f"
};

export const app = firebase.initializeApp(firebaseConfig);
export const db = app.database();
export const auth = app.auth();
