import React from "react";
import {
  Col,
  Row,
  Table,
  Button,
  Modal,
  Input,
  Icon,
  message,
  InputNumber,
  DatePicker,
  Select
} from "antd";
import { db } from "../firebase/index";
import { connect } from "react-redux";
import "./styles/createSupplier.css";
import moment from "moment";
const ButtonGroup = Button.Group;
const { TextArea } = Input;
const confirm = Modal.confirm;
const { Option } = Select;
const dateFormat = "DD/MM/YY h:mm a";

class supplierPurchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      purchases: [],
      addModal: false,
      editModal: false,
      prods: "",
      soldDate: null,
      pono: "",
      pricecode: "",
      customer: {},
      unmodcusts: {},
      currentSale: {},
      allowedProds: [],
      allDealerProds: [],
      products: []
    };

    this.columns = [
      {
        title: "Product Sold",
        dataIndex: "prods",
        key: "prods"
      },
      {
        title: "Date Sold",
        dataIndex: "soldDate",
        key: "soldDate"
      },
      {
        title: "P/O No",
        dataIndex: "pono",
        key: "pono"
      },
      {
        title: "Products",
        dataIndex: "",
        key: "az",
        render: record => {
          if (record.products) {
            return (
              record.products.map((data, index) => {
                return (
                  <span key={index}>{data.name} - {data.quantity}&nbsp;</span>
                )
              })
            )
          } else {
            return (
              <span>No Products</span>
            )
          }
        }
      },
      {
        title: "Total Price",
        dataIndex: "",
        key: "y",
        render: record => {
          let price = 0;
          if (record.products) {
            for (var i of record.products) {
              price += (parseFloat(i.price) * i.quantity);
            }
          }
          return (
            <span>{record.pricecode}{price}</span>
          )
        }
      },
      {
        title: "Customer",
        dataIndex: "",
        key: "yr",
        render: record => (
          <span>{this.state.unmodcusts[record.customer].name}</span>
        )
      },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        render: record => (
          <ButtonGroup>
            <Button
              onClick={() => {
                this.setState({ currentSale: record, editModal: true });
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                this.handleDelete(record.id);
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        )
      }
    ];
    this.updateDB();
  }

  addProduct() {
    let prods = this.state.products;
    prods.push({ id: "", name: "", quantity: "", price: 0 });
    this.setState({ products: prods })
  }

  removeProduct = index => {
    let prods = this.state.products;
    if (prods.length === 1) {
      prods = []
    } else {
      prods = prods.splice(index, 1);
    }
    this.setState({ products: prods })
  }

  updateDB = () => {
    const allcusts = datum => {
      const allsales = esp => {
        let custs = [];
        let purchases = [];
        // add custs
        if (datum.val() != null) {
          this.setState({ unmodcusts: datum.val() });
          let data = Object.keys(datum.val());
          data.forEach((dato, index) => {
            custs.push({ id: dato, ...datum.val()[dato] });
          });
          this.setState({ customers: custs });
        } else {
          this.setState({
            customers: []
          });
        }
        //add prods
        if (esp.val() != null) {
          let espo = Object.keys(esp.val());
          espo.forEach((data, index) => {
            purchases.push({ id: data, ...esp.val()[data] });
          });
          console.log(purchases);
          this.setState({ purchases: purchases });
        } else {
          this.setState({
            purchases: []
          });
        }
      };
      db.ref("purchases")
        .orderByChild("dealer")
        .equalTo(this.props.auth.user.uid)
        .once("value", allsales);
    };
    db.ref("customers")
      .orderByChild("dealer")
      .equalTo(this.props.auth.user.uid)
      .once("value", allcusts);

    const currentUsrData = datr => {
      if (datr.val().type) {
        if (datr.val().type === "International") {
          this.setState({ pricecode: "$" })
        } else {
          this.setState({ pricecode: "RM" })
        }
      } else {
        this.setState({ pricecode: "RM" })
      }
      if (datr.val().prods) {
        let allowed = datr.val().prods;
        const getProdName = df => {
          if (df) {
            df.forEach((dg, iz) => {
              for (let i = 0; i < allowed.length; i++) {
                if (allowed[i].id === dg.key) {
                  allowed[i] = { name: dg.val().name, ...allowed[i] };
                }
              }
            })
            console.log(allowed);
            this.setState({ allowedProds: allowed })
          }
        }
        db.ref("dealer_prods").once("value", getProdName);

      }
    }

    db.ref("users/" + this.props.auth.user.uid).once("value", currentUsrData);
  };

  handleSubmit = () => {
    if (this.state.products.length === 0) {
      message.warning("Kindly Add atleast one product");
      return;
    }
    if (
      this.state.prods === "" ||
      this.state.soldDate === null ||
      this.state.pono === "" ||
      this.state.date === null ||
      this.state.customer === {} ||
      this.state.pricecode === ""
    ) {
      message.warning("Kindly fill in all the fields");
      return;
    }
    const purchase = {
      prods: this.state.prods,
      soldDate: moment(this.state.soldDate).format("DD/MM/YY h:mm a"),
      pono: this.state.pono,
      customer: this.state.customer,
      dealer: this.props.auth.user.uid,
      pricecode: this.state.pricecode,
      products: this.state.products
    };
    db.ref("purchases")
      .push(purchase)
      .then(data => {
        message.success("Purchase added");
        this.updateDB();
        this.setState({ addModal: false });
      })
      .catch(err => {
        console.log(err.code);
      });
  };

  handleUpdate = () => {
    if (this.state.currentSale.products.length === 0) {
      message.warning("Kindly Add atleast one product");
      return;
    }
    db.ref("purchases/" + this.state.currentSale.id)
      .update({
        prods: this.state.currentSale.prods,
        soldDate: moment(this.state.currentSale.soldDate).format(
          "DD/MM/YY h:mm a"
        ),
        pono: this.state.currentSale.pono,
        customer: this.state.currentSale.customer,
        pricecode: this.state.currentSale.pricecode,
        products: this.state.currentSale.products
      })
      .then(data => {
        message.success("Purchase Details Updated");
        this.updateDB();
        this.setState({ editModal: false, currentSale: {} });
      })
      .catch(err => {
        message.error(err.code);
      });
  };

  handleDelete = id => {
    const self = this;
    confirm({
      title: "Delete",
      content: "Are you sure to delete this purchase ?",
      onOk() {
        db.ref("purchases/")
          .child(id)
          .remove()
          .then(data => {
            message.success("Purchase Deleted");
            self.updateDB();
          })
          .catch(err => {
            console.log(err.code);
          });
      },
      onCancel() { }
    });
  };

  render() {
    return (
      <div>
        <Row style={{ margin: 10 }}>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                if (this.state.allowedProds.length > 0) { this.setState({ addModal: true }) }
                else { message.warning("No Products available to sell") }
              }}
            >
              Add Purchase
            </Button>
          </Col>
        </Row>
        <Modal
          title="Add Purchase"
          visible={this.state.addModal}
          onOk={() => this.handleSubmit()}
          onCancel={() => this.setState({ addModal: false })}
          okText={"Save Purchase"}
        >
          <div>
            <Input
              className="all-inputs"
              size="large"
              placeholder="Enter P/O Number"
              onChange={pono => {
                this.setState({ pono: pono.target.value });
              }}
              value={this.state.pono}
            />
            <TextArea
              className="all-inputs"
              placeholder="Enter Product Details"
              size="large"
              autosize={{ minRows: 2, maxRows: 6 }}
              prefix={
                <Icon type="environment" style={{ color: "rgba(0,0,0,.25)" }} />
              }
              onChange={prods => {
                this.setState({ prods: prods.target.value });
              }}
              value={this.state.prods}
            />
            <DatePicker
              className="all-inputs"
              showTime
              placeholder="Sold Date"
              format={dateFormat}
              onChange={date => {
                console.log(date);
                this.setState({ soldDate: date });
              }}
              onOk={date => {
                this.setState({ soldDate: date });
              }}
              value={this.state.soldDate}
            />
            <Select
              showSearch
              allowClear
              className="all-inputs"
              placeholder="Select a Customer"
              onChange={cust => {
                this.setState({ customer: cust });
              }}
              onFocus={() => { }}
              onBlur={() => { }}
              onSearch={() => { }}
            >
              {this.state.customers.map((data, index) => {
                return (
                  <Option value={data.id} key={index}>
                    {"Customer Name : "}
                    {data.name}
                  </Option>
                );
              })}
            </Select>
            <Row style={{ margin: 10 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={() => this.addProduct()}
                >
                  Add Product
                </Button>
              </Col>
            </Row>
            {
              this.state.products && this.state.products.map((da, ind) => {
                return (
                  <Row type="flex" gutter={16} key={ind}>
                    <Col style={{ width: '30%' }}>
                      <Select
                        placeholder="Select Product"
                        size="large"
                        className="all-inputs"
                        style={{ width: "100%" }}
                        onChange={val => {
                          let temp = this.state.products;
                          for (var i of this.state.allowedProds) {
                            if (val === i.id) {
                              temp[ind] = i;
                            }
                          }
                          this.setState({ products: temp })
                        }}
                        value={this.state.products[ind].id}
                      >
                        {
                          this.state.allowedProds.map((da, ina) => {
                            return (
                              <Option value={da.id} key={ina}>{da.name}</Option>
                            )
                          })
                        }
                      </Select>
                    </Col>
                    <Col style={{ width: '20%' }}>
                      <InputNumber
                        className="all-inputs"
                        size="large"
                        placeholder={"Quantity"}
                        onChange={productionQuantity => {
                          let temp = this.state.products;
                          temp[ind].quantity = productionQuantity;
                          this.setState({ products: temp })
                        }}
                        value={this.state.products[ind].quantity || 0}
                      />
                    </Col>
                    <Col style={{ flex: 1, alignItems: 'center', width: '20%', marginTop: '5px' }}>
                      <p className="all-inputs" align="center">{this.state.pricecode} {this.state.products[ind].price * (this.state.products[ind].quantity || 0)}</p>
                    </Col>
                    <Col style={{ width: '30%' }}>
                      <Button
                        type="danger"
                        className="all-inputs"
                        onClick={() => this.removeProduct(ind)}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                )
              })
            }
          </div>
        </Modal>

        <Modal
          title="Edit Purchase"
          visible={this.state.editModal}
          onOk={() => this.handleUpdate()}
          onCancel={() => this.setState({ editModal: false })}
          okText={"Update Purchase"}
        >
          <div>
            <Input
              className="all-inputs"
              size="large"
              placeholder="Enter P/O Number"
              onChange={pono => {
                let temp = this.state.currentSale;
                temp.pono = pono.target.value;
                this.setState({ currentSale: temp });
              }}
              value={this.state.currentSale.pono}
            />
            <TextArea
              className="all-inputs"
              placeholder="Enter Product Details"
              size="large"
              autosize={{ minRows: 2, maxRows: 6 }}
              prefix={
                <Icon type="environment" style={{ color: "rgba(0,0,0,.25)" }} />
              }
              onChange={prods => {
                let temp = this.state.currentSale;
                temp.prods = prods.target.value;
                this.setState({ currentSale: temp });
              }}
              value={this.state.currentSale.prods}
            />
            <DatePicker
              className="all-inputs"
              showTime
              placeholder="Sold Date"
              format={dateFormat}
              onChange={date => {
                let temp = this.state.currentSale;
                temp.soldDate = date;
                this.setState({ currentSale: temp });
              }}
              onOk={date => {
                let temp = this.state.currentSale;
                temp.soldDate = date;
                this.setState({ currentSale: temp });
              }}
              value={moment(this.state.currentSale.soldDate)}
            />
            <Select
              showSearch
              allowClear
              className="all-inputs"
              placeholder="Select a Customer"
              onChange={cust => {
                let temp = this.state.currentSale;
                temp.customer = cust;
                this.setState({ currentSale: temp });
              }}
              value={this.state.currentSale.customer}
              onFocus={() => { }}
              onBlur={() => { }}
              onSearch={() => { }}
            >
              {this.state.customers.map((data, index) => {
                return (
                  <Option value={data.id} key={index}>
                    {"Customer Name : "}
                    {data.name}
                  </Option>
                );
              })}
            </Select>
            <Row style={{ margin: 10 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    this.state.currentSale.products.push({ id: "", name: "", quantity: 0, price: 0 });
                  }}
                >
                  Add Product
                </Button>
              </Col>
            </Row>
            {
              this.state.currentSale.products && this.state.currentSale.products.map((da, ind) => {
                return (
                  <Row type="flex" gutter={16} key={ind}>
                    <Col style={{ width: "30%" }}>
                      <Select
                        placeholder="Select Product"
                        size="large"
                        className="all-inputs"
                        style={{ width: "100%" }}
                        onChange={val => {
                          let temp = this.state.currentSale;
                          for (var i of this.state.allowedProds) {
                            if (val === i.id) {
                              temp.products[ind] = i;
                            }
                          }
                          this.setState({ currentSale: temp })
                        }}
                        value={this.state.currentSale.products[ind].id}
                      >
                        {
                          this.state.allowedProds.map((da, ina) => {
                            return (
                              <Option value={da.id} key={ina}>{da.name}</Option>
                            )
                          })
                        }
                      </Select>
                    </Col>
                    <Col style={{ width: "20%" }}>
                      <InputNumber
                        className="all-inputs"
                        size="large"
                        placeholder={"Quantity"}
                        onChange={productionQuantity => {
                          let temp = this.state.currentSale;
                          temp.products[ind].quantity = productionQuantity;
                          this.setState({ currentSale: temp })
                        }}
                        value={this.state.currentSale.products[ind].quantity || 0}
                      />
                    </Col>
                    <Col style={{ flex: 1, alignItems: 'center', width: "20%", marginTop: 5 }}>
                      <p className="all-inputs" align="center">{this.state.pricecode} {this.state.currentSale.products[ind].price * (this.state.currentSale.products[ind].quantity || 0)}</p>
                    </Col>
                    <Col style={{ width: "30%" }}>
                      <Button
                        type="danger"
                        className="all-inputs"
                        onClick={() => {
                          let temp = this.state.currentSale
                          if (this.state.currentSale.products.length === 1) {
                            temp.products = []
                          } else {
                            temp.products.splice(ind, 1)
                          }
                          this.setState({ currentSale: temp })
                        }}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                )
              })
            }
          </div>
        </Modal>
        <Row>
          <Col>
            <Table
              dataSource={this.state.purchases}
              columns={this.columns}
              rowKey={record => record.id}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps)(supplierPurchase);
