import React from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";
import blankContainer from "./containers/blankContainer";
import dashboardContainer from "./containers/dashboardContainer";
import { connect } from "react-redux";

class App extends React.Component {
  render() {
    if (this.props.auth.user) {
      return (
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/components" />
            </Route>
            <Route path="/components" component={dashboardContainer} />
          </Switch>
        </Router>
      );
    } else {
      return (
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/auth/login" />
            </Route>
            <Route path="/auth" component={blankContainer} />
            <Route exact path="/*">
              <Redirect to="/auth/login" />
            </Route>
          </Switch>
        </Router>
      );
    }
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps)(App);
