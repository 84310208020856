import React from "react";
import "./styles/blank.css";
import { Route } from "react-router-dom";
import Login from "../components/login";
import Forgot from "../components/forgot";

class blankContainer extends React.Component {
  render() {
    return (
      <div className="rootcontainer">
        <Route path={`${this.props.match.path}/login`} component={Login} />
        <Route path={`${this.props.match.path}/forgot`} component={Forgot} />
      </div>
    );
  }
}

export default blankContainer;
