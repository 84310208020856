import React from "react";
import {
  Col,
  Row,
  Table,
  Button,
  Modal,
  Input,
  Icon,
  Select,
  message,
  InputNumber
} from "antd";
import axios from "axios";
import { db } from "../firebase/index";
import "./styles/createSupplier.css";
const ButtonGroup = Button.Group;
const { Option } = Select;
const confirm = Modal.confirm;

class createSuppliers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      addModal: false,
      editModal: false,
      name: "",
      email: "",
      pass: "",
      type: "",
      category: "",
      prods:[],
      alldealerProds:[],
      currentSupplier: {}
    };

    this.columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "Email",
        dataIndex: "mail",
        key: "mail"
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type"
      },
      {
        title: "Category",
        dataIndex: "cat",
        key: "cat"
      },
      {
        title: "Products",
        dataIndex: "",
        key: "y",
        render: record => {
          if(record.prods){
            return(
              record.prods.map((data,index)=>{
                for (let i of this.state.alldealerProds){
                  if(i.id===data.id){
                    return (
                      <p key={index}>{i.name} - {record.type==="International" ? "$" : "RM"} {data.price} per KG</p>
                    )
                  }
                }
              })
            )
          }else{
            return(<p>No Products</p>)
          }
        }
      },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        render: record => (
          <ButtonGroup>
            <Button
              onClick={() => {
                this.setState({ currentSupplier: record, editModal: true });
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                this.handleDelete(record.uid);
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        )
      }
    ];
    this.updateDB();
  }

  updateDB = () => {
    const allsupps = datum => {
      this.setState({
        products: datum.val() ? Object.values(datum.val()) : []
      });
    };
    db.ref("users")
      .orderByChild("role")
      .equalTo("dealer")
      .once("value", allsupps);


    const setProds = datum => {
        if(datum){
          let products = [];
          datum.forEach((data,index)=>{
              products.push({id:data.key,...data.val()})
          })
          this.setState({alldealerProds:products})
        }
    }

    db.ref("dealer_prods")
    .once("value", setProds);
  };

  handleSubmit = () => {
    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.pass === "" ||
      this.state.type === "" ||
      this.state.category === ""
    ) {
      message.warning("Kindly fill in all the fields");
      return;
    }
    const supplier = {
      name: this.state.name,
      mail: this.state.email,
      pass: this.state.pass,
      type: this.state.type,
      cat: this.state.category,
      role: "dealer"
    };
    axios
      .post(
        "https://us-central1-dealer-management-system-f3705.cloudfunctions.net/createUserSupplier",
        supplier
      )
      .then(data => {
        message.success("Dealer added");
        this.updateDB();
        this.setState({ addModal: false });
      })
      .catch(err => {
        console.log(err.code);
      });
  };

  handleUpdate = () => {
    if(this.state.currentSupplier.prods){
      this.state.currentSupplier.prods.forEach((data,index)=>{
        if(!data.id){
          message.warning("Kindly fill in all the fields");
          return;
        }
      })
    }

    db.ref("users/" + this.state.currentSupplier.uid)
      .update({
        name: this.state.currentSupplier.name,
        type: this.state.currentSupplier.type,
        cat: this.state.currentSupplier.cat,
        prods:this.state.currentSupplier.prods || []
      })
      .then(data => {
        message.success("Dealer Details Updated");
        this.updateDB();
        this.setState({ editModal: false, currentSupplier: {} });
      })
      .catch(err => {
        message.error(err.code);
      });
  };

  handleDelete = uid => {
    const self = this;
    confirm({
      title: "Delete",
      content: "Are you sure to delete this supplier ?",
      onOk() {
        axios
          .post(
            "https://us-central1-dealer-management-system-f3705.cloudfunctions.net/deleteUserSupplier",
            { uid: uid }
          )
          .then(data => {
            message.success("Dealer Deleted");
            self.updateDB();
          })
          .catch(err => {
            console.log(err.code);
          });
      },
      onCancel() {}
    });
  };

  increaseProduct(){
    let temp = this.state.currentSupplier;
    if(temp.prods){
      temp.prods.push({id:'',price:''});
    }else{
      temp.prods = [{id:'',price:''}]
    }
    this.setState({currentSupplier:temp});
  }

  render() {
    return (
      <div>
        <Row style={{ margin: 10 }}>
          <Col>
            <Button
              type="primary"
              onClick={() => this.setState({ addModal: true })}
            >
              Add Dealer
            </Button>
          </Col>
        </Row>
        <Modal
          title="Add Dealer"
          visible={this.state.addModal}
          onOk={() => this.handleSubmit()}
          onCancel={() => this.setState({ addModal: false })}
          okText={"Save Dealer"}
        >
          <div>
            <Input
              className="all-inputs"
              size="large"
              placeholder="Enter Name"
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              onChange={name => {
                this.setState({ name: name.target.value });
              }}
              value={this.state.name}
            />
            <Input
              className="all-inputs"
              size="large"
              placeholder="Enter Email"
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              onChange={email => {
                this.setState({ email: email.target.value });
              }}
              value={this.state.email}
            />
            <Input.Password
              className="all-inputs"
              size="large"
              placeholder="Enter Password"
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              onChange={pass => {
                this.setState({ pass: pass.target.value });
              }}
              value={this.state.pass}
            />
            <Select
              placeholder="Select Dealer Type"
              size="large"
              className="all-inputs"
              style={{ width: "100%" }}
              onChange={val => {
                this.setState({ type: val });
              }}
            >
              <Option value="Domestic">Domestic</Option>
              <Option value="International">International</Option>
            </Select>
            <Select
              placeholder="Select Dealer Category"
              size="large"
              className="all-inputs"
              style={{ width: "100%" }}
              onChange={val => {
                this.setState({ category: val });
              }}
            >
              <Option value="Textile">Textile</Option>
              <Option value="Metal">Metal</Option>
              <Option value="Others">Others</Option>
            </Select>
          </div>
        </Modal>

        <Modal
          title="Edit Dealer"
          visible={this.state.editModal}
          onOk={() => this.handleUpdate()}
          onCancel={() => this.setState({ editModal: false })}
          okText={"Update Dealer"}
        >
          <div>
            <Input
              className="all-inputs"
              size="large"
              placeholder="Enter Name"
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              onChange={name => {
                let temp = this.state.currentSupplier;
                temp.name = name.target.value;
                this.setState({ currentSupplier: temp });
              }}
              value={this.state.currentSupplier.name}
            />
            <Select
              placeholder="Select Dealer Type"
              size="large"
              className="all-inputs"
              style={{ width: "100%" }}
              onChange={val => {
                let temp = this.state.currentSupplier;
                temp.type = val;
                this.setState({ currentSupplier: temp });
              }}
              value={this.state.currentSupplier.type}
            >
              <Option value="Domestic">Domestic</Option>
              <Option value="International">International</Option>
            </Select>
            <Select
              placeholder="Select Dealer Category"
              size="large"
              className="all-inputs"
              style={{ width: "100%" }}
              onChange={val => {
                let temp = this.state.currentSupplier;
                temp.cat = val;
                this.setState({ currentSupplier: temp });
              }}
              value={this.state.currentSupplier.cat}
            >
              <Option value="Textile">Textile</Option>
              <Option value="Metal">Metal</Option>
              <Option value="Others">Others</Option>
            </Select>
            <br/>
            <Button
              type="primary"
              onClick={() => this.increaseProduct()}
            >
              Add Product
            </Button>
            {
              this.state.currentSupplier.prods && this.state.currentSupplier.prods.map((data,index)=>{
                return(
                  <Row type="flex" gutter={16}>
                    <Col span={8}>
                      <Select
                        placeholder="Select Product"
                        size="large"
                        className="all-inputs"
                        style={{ width: "100%" }}
                        onChange={val => {
                          let temp = this.state.currentSupplier;
                          temp.prods[index].id = val;
                          this.setState({ currentSupplier: temp });
                        }}
                        value={this.state.currentSupplier.prods[index].id}
                      >
                        {
                          this.state.alldealerProds.map((da,ina)=>{
                            return(
                              <Option value={da.id}>{da.name}</Option>
                            )
                          })
                        }
                      </Select>
                    </Col>
                    <Col span={8}>
                      <InputNumber
                        className="all-inputs"
                        size="large"
                        placeholder={"Price Per KG"}
                        onChange={price => {
                          let temp = this.state.currentSupplier;
                          temp.prods[index].price=price;
                          this.setState({
                            currentSupplier: temp
                          });
                        }}
                        value={this.state.currentSupplier.prods[index].price}
                      />
                    </Col>
                    <Col span={8}>
                      <Button
                        type="danger"
                        className="all-inputs"
                        onClick={() => {
                          let temp = this.state.currentSupplier;
                          temp.prods.splice(index,1);
                          this.setState({
                            currentSupplier: temp
                          });
                        }}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                )
              })
            }
          </div>
        </Modal>
        <Row>
          <Col>
            <Table
              dataSource={this.state.products}
              columns={this.columns}
              rowKey={record => record.uid}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default createSuppliers;
