import React from "react";
import { Card, Col, Row, Input, Icon, Button, message } from "antd";
import { Link } from "react-router-dom";
import { auth } from "../firebase/index";
import "./styles/login.css";

class Forgot extends React.Component {
  constructor() {
    super();
    this.state = {
      email: ""
    };
  }
  render() {
    return (
      <div>
        <Row gutter={16}>
          <Col>
            <Card
              title="Dealer Management System - Forgot Password"
              className="cardAlignment"
            >
              <Row className="inputContainers">
                <Input
                  className="allinputs"
                  size="large"
                  placeholder="Email Address"
                  prefix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.25)" }}
                      className="iconMarginCorrection"
                    />
                  }
                  onChange={mail => {
                    this.setState({ email: mail.target.value });
                  }}
                />
              </Row>
              <Row className="inputContainers">
                <Col>
                  <Button
                    type="primary"
                    className="submit-button"
                    size="large"
                    onClick={this.onRequest}
                  >
                    Send Reset Link
                  </Button>
                </Col>
                <Col>
                  <Link to="login">
                    <Button
                      type="warning"
                      className="submit-button"
                      size="large"
                    >
                      Back to Login
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
  onRequest = () => {
    if (!this.state.email) {
      message.warning("Kindly fill in your email address");
      return;
    }
    auth
      .sendPasswordResetEmail(this.state.email, {})
      .then(() => {
        message.success("Check your email to reset password");
      })
      .catch(err => {
        message.code(err.code);
      });
  };
}

export default Forgot;
