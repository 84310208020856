import React from "react";
import {
    Col,
    Row,
    Table,
    Button,
    Select,
    Input,
    message
} from "antd";
import { db } from "../firebase/index";
import { connect } from "react-redux";
import "./styles/createSupplier.css";
const { Option } = Select;

class AdminHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allDealers: [],
            allCusts: [],
            subCusts: [],
            allHistory: [],
            selectedDealer: null,
            selectedCust: null,
            selectedHistory: []
        };

        this.columns = [
            {
                title: "Customer",
                dataIndex: "",
                key: "y",
                render: record => {
                    let index = this.state.allCusts.findIndex(cust => cust.id === record.customer);
                    if (index >= 0) {
                        return (
                            <span>{this.state.allCusts[index].name}</span>
                        )
                    } else {
                        return (
                            <span>Deleted Customer</span>
                        )
                    }
                }
            },
            {
                title: "Visit Date",
                dataIndex: "visitDate",
                key: "visitDate"
            },
            {
                title: "Reason",
                dataIndex: "reason",
                key: "reason"
            },
            {
                title: "Feedback",
                dataIndex: "comment",
                key: "comment"
            },
            {
                title: "Admin Comment",
                dataIndex: "",
                key: "x",
                render: record => {
                    return (
                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Col style={{ width: '60%' }}>
                                <Input
                                    className="all-inputs"
                                    placeholder="Enter Comment"
                                    onChange={comment => {
                                        let index = this.state.selectedHistory.findIndex(dato=>dato.id===record.id);
                                        let temp = this.state.selectedHistory;
                                        temp[index].admincomment = comment.target.value;
                                        this.setState({ selectedHistory: temp });
                                    }}
                                    value={record.admincomment || ""}
                                />
                            </Col>
                            <Col style={{ width: '20%' }}>
                                <Button
                                    onClick={() => this.updateComment(record)}>
                                    Update
                                </Button>
                            </Col>
                        </Row>
                    )
                }
            }
        ];
        this.initData();
    }

    updateComment(record){
        db.ref("history/"+record.id).update({
            admincomment:record.admincomment
        }).then(()=>{
            message.success("Comment Updated");
        }).catch(err=>{
            alert(err.code);
        })
    }

    initData() {
        db.ref("users")
            .orderByChild("role")
            .equalTo("dealer")
            .once("value", (data) => {
                if (data.val()) {
                    let dealers = [];
                    data.forEach((datum, indo) => {
                        dealers.push({ id: datum.key, index: indo, ...datum.val() })
                    })
                    this.setState({ allDealers: dealers });
                }
            })

        db.ref("customers")
            .once("value", (data) => {
                if (data.val()) {
                    let customers = [];
                    data.forEach((datum, indo) => {
                        customers.push({ id: datum.key, index: indo, ...datum.val() });
                    })
                    this.setState({ allCusts: customers });
                }
            })

        db.ref("history")
            .once("value", (data) => {
                if (data.val()) {
                    let history = [];
                    data.forEach((datum, indo) => {
                        history.push({ id: datum.key, index: indo, ...datum.val() });
                    })
                    this.setState({ allHistory: history });
                }
            })
    }

    getData() {

        let history = [];
        for (var i of this.state.allHistory) {
            if (this.state.selectedCust === i.customer && this.state.selectedDealer === i.dealer) {
                history.push(i);
            }
        }
        this.setState({ selectedHistory: history })

    }

    getCustomers(deal) {
        let customers = [];
        for (var i of this.state.allCusts) {
            if (i.dealer === deal) {
                customers.push(i)
            }
        }
        this.setState({ subCusts: customers });
    }

    render() {
        return (
            <div>
                <Row style={{ margin: 10, flex: 1, alignItems: 'center', flexDirection: 'row', display: 'flex' }}>
                    <Col style={{ width: '30%', paddingRight: 20 }}>
                        <Select
                            showSearch
                            allowClear
                            className="all-inputs"
                            placeholder="Select a Dealer"
                            onChange={deal => {
                                this.getCustomers(deal);
                                this.setState({ selectedDealer: deal });
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }}
                            onSearch={() => { }}
                        >
                            {this.state.allDealers.map((data, index) => {
                                return (
                                    <Option value={data.id} key={index}>
                                        {"Dealer Name : "}
                                        {data.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Col>
                    <Col style={{ width: '30%', paddingRight: 20 }}>
                        <Select
                            showSearch
                            allowClear
                            className="all-inputs"
                            placeholder="Select a Customer"
                            onChange={cust => {
                                this.setState({ selectedCust: cust });
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }}
                            onSearch={() => { }}
                        >
                            {this.state.subCusts.map((data, index) => {
                                return (
                                    <Option value={data.id} key={index}>
                                        {"Customer Name : "}
                                        {data.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Col>
                    <Col style={{ width: '30%' }}>
                        <Button
                            type="primary"
                            onClick={() => this.getData()}
                        >
                            Get History
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table
                            dataSource={this.state.selectedHistory}
                            columns={this.columns}
                            rowKey={record => record.id}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

export default connect(mapStateToProps)(AdminHistory);
