
import React from "react";
import {
  Col,
  Row,
  Table,
  Button,
  Modal,
  Input,
  message,
  DatePicker,
  Select
} from "antd";
import { db } from "../firebase/index";
import { connect } from "react-redux";
import "./styles/createSupplier.css";
import moment from "moment";
const ButtonGroup = Button.Group;
const { TextArea } = Input;
const confirm = Modal.confirm;
const { Option } = Select;
const dateFormat = "DD/MM/YY h:mm a";

class supplierHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      allData: [],
      addModal: false,
      editModal: false,
      visitDate: null,
      reason: "",
      comment: "",
      customer: {},
      currentHistory: {},
    };

    this.columns = [
      {
        title: "Customer",
        dataIndex: "",
        key: "y",
        render: record => {
          let index = this.state.customers.findIndex(cust => cust.id === record.customer);
          if (index >= 0) {
            return (
              <span>{this.state.customers[index].name}</span>
            )
          } else {
            return (
              <span>Deleted Customer</span>
            )
          }
        }
      },
      {
        title: "Visit Date",
        dataIndex: "visitDate",
        key: "visitDate"
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: "reason"
      },
      {
        title: "Feedback",
        dataIndex: "comment",
        key: "comment"
      },
      {
        title: "Admin Feedback",
        dataIndex: "admincomment",
        key: "admincomment"
      },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        render: record => (
          <ButtonGroup>
            <Button
              onClick={() => {
                this.setState({ currentHistory: record, editModal: true });
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                this.handleDelete(record.id);
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        )
      }
    ];
    this.updateDB();
  }

  updateDB = () => {
    db.ref("history")
      .orderByChild("dealer")
      .equalTo(this.props.auth.user.uid)
      .on("value", (data) => {
        if (data.val) {
          let history = [];
          data.forEach((datum, indo) => {
            history.push({ id: datum.key, ...datum.val() });
          })
          this.setState({ allData: history })
        }
      })
    db.ref("customers")
      .orderByChild("dealer")
      .equalTo(this.props.auth.user.uid)
      .once("value", (data) => {
        if (data.val()) {
          let customers = [];
          data.forEach((datum, indo) => {
            customers.push({ id: datum.key, ...datum.val() });
          })
          this.setState({ customers })
        }
      });
  };

  handleSubmit = () => {
    if (
      this.state.reason === "" ||
      this.state.reason === null ||
      this.state.customer === {} ||
      this.state.visitDate === null ||
      this.state.comment === "" ||
      this.state.comment === null
    ) {
      message.warning("Kindly fill in all the fields");
      return;
    }
    const history = {
      customer: this.state.customer,
      visitDate: moment(this.state.visitDate).format("DD/MM/YY h:mm a"),
      dealer: this.props.auth.user.uid,
      reason: this.state.reason,
      comment: this.state.comment
    };
    db.ref("history")
      .push(history)
      .then(data => {
        message.success("Record added");
        this.updateDB();
        this.setState({ addModal: false });
      })
      .catch(err => {
        console.log(err.code);
      });
  };

  handleUpdate = () => {

    db.ref("history/" + this.state.currentHistory.id)
      .update({
        customer: this.state.currentHistory.customer,
        visitDate: moment(this.state.currentHistory.visitDate).format("DD/MM/YY h:mm a"),
        dealer: this.props.auth.user.uid,
        reason: this.state.currentHistory.reason,
        comment: this.state.currentHistory.comment
      })
      .then(data => {
        message.success("Record Updated");
        this.updateDB();
        this.setState({ editModal: false, currentHistory: {} });
      })
      .catch(err => {
        message.error(err.code);
      });
  };

  handleDelete = id => {
    const self = this;
    confirm({
      title: "Delete",
      content: "Are you sure to delete this purchase ?",
      onOk() {
        db.ref("history/")
          .child(id)
          .remove()
          .then(data => {
            message.success("Record Deleted");
            self.updateDB();
          })
          .catch(err => {
            console.log(err.code);
          });
      },
      onCancel() { }
    });
  };

  render() {
    return (
      <div>
        <Row style={{ margin: 10 }}>
          <Col>
            <Button
              type="primary"
              onClick={() => this.setState({ addModal: true })}
            >
              Add Record
            </Button>
          </Col>
        </Row>
        <Modal
          title="Add History"
          visible={this.state.addModal}
          onOk={() => this.handleSubmit()}
          onCancel={() => this.setState({ addModal: false })}
          okText={"Save History"}
        >
          <div>
            <Select
              showSearch
              allowClear
              className="all-inputs"
              placeholder="Select a Customer"
              onChange={cust => {
                this.setState({ customer: cust });
              }}
              onFocus={() => { }}
              onBlur={() => { }}
              onSearch={() => { }}
            >
              {this.state.customers.map((data, index) => {
                return (
                  <Option value={data.id} key={index}>
                    {"Customer Name : "}
                    {data.name}
                  </Option>
                );
              })}
            </Select>
            <DatePicker
              className="all-inputs"
              showTime
              placeholder="Date of Visit"
              format={dateFormat}
              onChange={date => {
                this.setState({ visitDate: date });
              }}
              onOk={date => {
                this.setState({ visitDate: date });
              }}
              value={this.state.visitDate}
            />
            <TextArea
              className="all-inputs"
              placeholder="Purpose of visit"
              size="large"
              autosize={{ minRows: 2, maxRows: 6 }}
              onChange={reason => {
                this.setState({ reason: reason.target.value });
              }}
              value={this.state.reason}
            />
            <TextArea
              className="all-inputs"
              placeholder="Customer Feedback"
              size="large"
              autosize={{ minRows: 2, maxRows: 6 }}
              onChange={comment => {
                this.setState({ comment: comment.target.value });
              }}
              value={this.state.comment}
            />
          </div>
        </Modal>

        <Modal
          title="Edit History"
          visible={this.state.editModal}
          onOk={() => this.handleUpdate()}
          onCancel={() => this.setState({ editModal: false })}
          okText={"Update History"}
        >
          <div>
            <Select
              showSearch
              allowClear
              className="all-inputs"
              placeholder="Select a Customer"
              onChange={cust => {
                let temp = this.state.currentHistory;
                temp.customer = cust;
                this.setState({ currentHistory: temp });
              }}
              onFocus={() => { }}
              onBlur={() => { }}
              onSearch={() => { }}
              value={this.state.currentHistory.customer}
            >
              {this.state.customers.map((data, index) => {
                return (
                  <Option value={data.id} key={index}>
                    {"Customer Name : "}
                    {data.name}
                  </Option>
                );
              })}
            </Select>
            <DatePicker
              className="all-inputs"
              showTime
              placeholder="Date of Visit"
              format={dateFormat}
              onChange={date => {
                let temp = this.state.currentHistory;
                temp.visitDate = date;
                this.setState({ currentHistory: temp });
              }}
              onOk={date => {
                let temp = this.state.currentHistory;
                temp.visitDate = date;
                this.setState({ currentHistory: temp });
              }}
              value={moment(this.state.currentHistory.visitDate)}
            />
            <TextArea
              className="all-inputs"
              placeholder="Purpose of visit"
              size="large"
              autosize={{ minRows: 2, maxRows: 6 }}
              onChange={reason => {
                let temp = this.state.currentHistory;
                temp.reason = reason.target.value;
                this.setState({ currentHistory: temp });
              }}
              value={this.state.currentHistory.reason}
            />
            <TextArea
              className="all-inputs"
              placeholder="Customer Feedback"
              size="large"
              autosize={{ minRows: 2, maxRows: 6 }}
              onChange={comment => {
                let temp = this.state.currentHistory;
                temp.comment = comment.target.value;
                this.setState({ currentHistory: temp });
              }}
              value={this.state.currentHistory.comment}
            />
          </div>
        </Modal>
        <Row>
          <Col>
            <Table
              dataSource={this.state.allData}
              columns={this.columns}
              rowKey={record => record.id}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps)(supplierHistory);

