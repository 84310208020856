import React from "react";
import { Card, Col, Row, Input, Icon, Button, message } from "antd";
import { connect } from "react-redux";
import { setAuth } from "../actions/index";
import { Link } from "react-router-dom";
import { auth } from "../firebase/index";
import { withRouter } from "react-router-dom";
import { db } from "../firebase/index";
import "./styles/login.css";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
  }

  render() {
    return (
      <div>
        <Row gutter={16}>
          <Col>
            <Card
              title="Dealer Management System - Login"
              className="cardAlignment"
            >
              <Row className="inputContainers">
                <Input
                  className="allinputs"
                  size="large"
                  placeholder="Email Address"
                  prefix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.25)" }}
                      className="iconMarginCorrection"
                    />
                  }
                  onChange={mail => {
                    this.setState({ email: mail.target.value });
                  }}
                  value={this.state.email}
                />
              </Row>
              <Row className="inputContainers">
                <Input.Password
                  className="allinputs"
                  placeholder="Password"
                  size="large"
                  prefix={
                    <Icon
                      type="lock"
                      style={{ color: "rgba(0,0,0,.25)" }}
                      className="iconMarginCorrection"
                    />
                  }
                  onChange={pass => {
                    this.setState({ password: pass.target.value });
                  }}
                  value={this.state.password}
                />
              </Row>
              <Row className="inputContainers">
                <Col>
                  <Button
                    type="primary"
                    className="submit-button"
                    size="large"
                    onClick={this.onLogin}
                  >
                    Login
                  </Button>
                </Col>
                <Col>
                  <Link to="forgot">
                    <Button
                      type="warning"
                      className="submit-button"
                      size="large"
                    >
                      Forgot Password
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  onLogin = async () => {
    if (!this.state.email || !this.state.password) {
      message.warning("Kindly fill in both email and password");
      return;
    }
    await auth
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(async data => {
        let response = dataresp => {
          let autho = {
            mail: dataresp.val().email,
            name: dataresp.val().name,
            role: dataresp.val().role,
            uid: data.user.uid
          };
          this.props.setAuth(autho);
          message.success("Login Successful");
          if (dataresp.val().role === "superAdmin") {
            this.props.history.push("/components/suppliers");
          } else if (dataresp.val().role === "dealer") {
            this.props.history.push("/components/customers");
          }
        };
        await db.ref("/users/" + data.user.uid).once("value", response);
      })
      .catch(err => {
        console.log(err);
        message.error(err.code);
      });
  };
}

export default connect(
  null,
  { setAuth }
)(withRouter(Login));
